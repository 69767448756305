.button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0a1a35;
    color: #FEFEFE;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    width: 120px;
    height: 40px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.5s;
    font-family: Tahoma;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
  

.button:hover {
    background-color: #153875;
}